import { PermissionEnum } from '@/enums/PermissionEnum';
import { OrdersKeys } from '@/enums/RouteKeyEnums';
import { Orders } from '@/enums/RouteNameEnums';

export default [
    {
        name: Orders.OrderList,
        path: '/orders',
        component: () => import('@/views/orders/views/OrderList.vue'),
        meta: {
            requiresAuth: true,
            canAddToFavorite: true,
            layout: 'default',
            permissions: { read: PermissionEnum.OrdersRead },
            key: OrdersKeys.OrderList,
        },
    },
    {
        name: Orders.OrderDetail,
        path: '/orders/:id',
        component: () => import('@/views/orders/views/OrderDetail.vue'),
        props: (route: { params: { id: number; } }) => ({ id: route.params.id }),
        meta: {
            requiresAuth: true,
            layout: 'default',
            navActiveLink: Orders.OrderList,
            breadcrumb: [{ title: Orders.OrderList }],
            hideBreadcrumbs: true,
            permissions: { read: PermissionEnum.OrdersRead },
            key: OrdersKeys.OrderDetail,
        },
    },
];
