import { PermissionEnum } from '@/enums/PermissionEnum';
import { OrdersKeys } from '@/enums/RouteKeyEnums';
import { Orders } from '@/enums/RouteNameEnums';

export default [
    {
        title: Orders.OrderList,
        to: Orders.OrderList,
        icon: { icon: 'mdi-cart-variant' },
        action: PermissionEnum.OrdersRead,
        key: OrdersKeys.OrderList,
    },
];
